import React from "react";
import WelcomePage from "../components/organisms/WelcomePage/WelcomePage";
import { PageTitle, SEO } from "../modules/seo";

const Welcome = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.Welcome} />
      <WelcomePage />
    </div>
  );
};

export default Welcome;
